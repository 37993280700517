<template>
  <div class="popular" @click="selectCategory">
    <div class="categories">
      <div v-if="!$route.path.includes('/sh-retailer')" class="all_categories">
        <router-link v-if="id === 'all'" :to="'/retailer/list-of-products'">
          <span   class="cate">
            <img :src="image" loading="lazy" :alt="title" class="icon" />
            <span class="categoryTitle">{{ title }}</span>
          </span>
        </router-link>
        <router-link v-else :to="'/retailer/list-of-products?category=' + id">
          <span   class="cate">
            <img :src="image" loading="lazy" :alt="title" class="icon" />
            <span class="categoryTitle">{{ title }}</span>
          </span>
        </router-link>
      </div>
      <div v-else class="all_categories">
        <router-link
          v-if="id === 'all'"
          :to="'/sh-retailer/products/categories'"
        >
          <span   class="cate">
            <img loading="lazy" :src="image" :alt="title" class="icon" />
            <span class="categoryTitle">{{ title }}</span>
          </span>
        </router-link>
        <router-link v-else :to="'./products/categories?category=' + id">
          <span   class="cate">
            <img loading="lazy" :src="image" :alt="title" class="icon" />
            <span class="categoryTitle">{{ title }}</span>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomePage from "@/services/HomePage";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      category: null,
    };
  },
  components: {
    HomePage,
  },
  props: {
    id: { type: Number, required: true },
    image: { type: String, required: false },
    title: { type: String, required: false },
  },

  methods: {
    ...mapActions(["retailer/updateBreadcrumbs"]),
    setBreadcrumbs(paths) {
      this["retailer/updateBreadcrumbs"](paths);
    },
    selectCategory() {
      console.log("change category");
      this.setBreadcrumbs([
        {
          title: "List of products",
          href: "Retailer Home",
        },
        {
          title: this.title,
          href: undefined,
        },
      ]);
    },
    popularCategory(categoryid) {
      var e = {
        sort_by_object: {
          id: categoryid,
        },
      };
      this.SortByObject(e, "category");
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  justify-content: center;
}
@media (max-width: 769px) {
  .categories {
    width: 100%;
    .all_categories {
      width: 100%;
      a {
        width: 100%;
      }
    }
  }
}
.popular_categories {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 22px;
}

.all_categories {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 24px;
}

.cate {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 18px;
  gap: 24px;
  width: 100%;
  height: 58px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 0px 2px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
  .icon {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 769px) {
    justify-content: flex-start;
  }
}

.categoryTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
</style>
