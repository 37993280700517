<template>
  <div class="banner__container">
    <div
      class="banner__container__type4"
      v-for="type4num in type4count"
      :key="type4num"
    >
      <div class="link-container" v-if="$route.path.includes('sh-retailer')">
        <router-link
          :to="updatedBanner[(type4num - 1) * 4 + indexType4 - 1].link"
          v-for="indexType4 in 4"
          :key="indexType4"
        >
          <img
            alt="vgang banner"
            :src="updatedBanner[(type4num - 1) * 4 + indexType4 - 1].image"
            class="banner__container__type4__image"
        /></router-link>
      </div>
      <div class="link-container" v-else>
        <a
          aria-label="Go to link"
          target="_blank"
          :href="updatedBanner[(type4num - 1) * 4 + indexType4 - 1].link"
          v-for="indexType4 in 4"
          :key="indexType4"
        >
          <img
            alt="vgang banner"
            :src="updatedBanner[(type4num - 1) * 4 + indexType4 - 1].image"
            class="banner__container__type4__image"
        /></a>
      </div>
    </div>
    <div
      class="banner__container__type2"
      v-for="type2num in type2count"
      :key="type2num"
    >
      <div class="link-container" v-if="!$route.path.includes('sh-retailer')">
        <a
          aria-label="Go to link"
          :href="
            updatedBanner[(type2num - 1) * 4 + indexType2 + 4 * type4count].link
          "
          target="_blank"
          v-for="indexType2 in 2"
          :key="indexType2"
        >
          <img
            alt="vgang banner"
            :src="
              updatedBanner[(type2num - 1) * 4 + indexType2 + 4 * type4count]
                .image
            "
            class="banner__container__type2__image"
          />
        </a>
      </div>
      <div class="link-container" v-else>
        <router-link
          :to="
            updatedBanner[(type2num - 1) * 4 + indexType2 + 4 * type4count].link
          "
          v-for="indexType2 in 2"
          :key="indexType2"
        >
          <img
            alt="vgang banner"
            :src="
              updatedBanner[(type2num - 1) * 4 + indexType2 + 4 * type4count]
                .image
            "
            class="banner__container__type2__image"
        /></router-link>
      </div>
    </div>
    <div
      class="banner__container__type1"
      v-for="type1num in type1count"
      :key="type1num"
    >
      <a
        aria-label="Go to link"
        :href="
          updatedBanner[type1num + 4 * type4count + 2 * type2count - 1].link
        "
      >
        <img
          alt="vgang banner"
          :src="
            updatedBanner[type1num + 4 * type4count + 2 * type2count - 1].image
          "
          class="banner__container__type1__image"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updatedBanner: [],
    };
  },
  props: {
    banners: { type: Array, required: true },
  },

  watch: {
    banners: function (newVal, oldVal) {
      // This function will be called whenever myProp changes
      this.updateBanner();

      // You can perform custom logic here based on the new and old values
    },
  },
  mounted() {},
  methods: {
    updateBanner() {
      if (this.$route.path.includes("sh-retailer")) {
        
        this.updatedBanner = this.banners.map((banner) => {
          if(banner.link.includes("list-of-products")) {
            
            
            return {
            ...banner, // Spread the existing properties
            link: banner.link.replace(
              new RegExp("https://vgang.io/retailer/list-of-products", "g"),
              "/sh-retailer/products/categories"
            ),

          };
          } else {
            return {
            ...banner, // Spread the existing properties
            link: banner.link.replace(
            /https:\/\/vgang\.io\/retailer\//g,
          ""
        ),
          };
          }
         
        });
      } else {
        this.updatedBanner = this.banners;
      }
    },
  },
  computed: {
    type4count() {
      return Math.trunc(this.banners.length / 4);
    },
    type2count() {
      return Math.trunc(
        (this.banners.length - Math.trunc(this.banners.length / 4) * 4) / 2
      );
    },
    type1count() {
      return this.banners.length % 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
  &__type4 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    @include md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 14px;
    }
    @include md {
      display: grid;
      grid-template-columns: 1fr;
      gap: 14px;
    }

    .link-container {
      display: flex;
      justify-content: space-between;
      @include md {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
      }
      @include sm {
        display: grid;
        grid-template-columns: 1fr;
        gap: 14px;
      }
    }
    a {
      width: 24%;
      overflow: hidden;
      border-radius: 15px;
      @include md {
        width: 100%;
      }
    }
    &__image {
      width: 100%;
      height: 100%;
    }
  }
  &__type2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    a {
      width: 49.5%;
      border-radius: 15px;
      overflow: hidden;
      max-height: 200px;
    }
    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  &__type1 {
    display: flex;
    justify-content: space-between;
    width: 100.5%;
    margin-left: -5px;
    margin-bottom: 15px;
    a {
      width: 100%;
      border-radius: 25px;
      overflow: hidden;
      max-height: 250px;
    }
    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
