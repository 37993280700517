// import HomePageData from "@/mocks/HomePage";
//
// export default {
//     getHomePageData() {
//         return HomePageData.HomePageData;
//     }
// }

import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getHomePageData() {
    return api.get("retailers/home", {}, { headers: authHeader() });
  },
};
