<template>
  <div>
    <div class="popular_categories mt-0">Trending categories</div>
    <br />
    <div class="cate flex-container">
      <TrendingCategory
        v-for="category in categories"
        :key="category.id"
        :title="category.title"
        :image="category.image"
        :alt="category.title"
        :id="category.id"
      >
      </TrendingCategory>
    </div>

    <div class="cate flex-container-banners">
      <Littlebanner :banners="banners"> </Littlebanner>
    </div>

    <list />
  </div>
</template>

<script>
// Components
import TrendingCategory from "@/views/retailer/list-of-products/TrendingCategory.vue";
import list from "@/views/retailer/list-of-products/list.vue";
// Services
import HomePage from "@/services/HomePage";
import Littlebanner from "@/views/retailer/list-of-products/Littlebanner.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Retailerhome",
  components: {
    TrendingCategory,
    Littlebanner,
    HomePage,
    list,
  },
  metaInfo: {
    // Set the meta description
    meta: [
      {
        name: 'vang, product list',
        content: 'product list',
      },
    ],
  },
  data() {
    return {
      products: [],
      categories: [],
      banners: [],
      productSections: [],
      userType: "retailer", // TODO: Get the user type from user object
      showBanner: true,
      close: true,
    };
  },
  mounted() {
    this.setBreadcrumbs();
    this.getPageData();
  },
  computed: {
    ...mapGetters({
      breadcrumbsPaths: "retailer/Breadcrumbs",
    }),
  },
  methods: {
    ...mapActions(["retailer/updateBreadcrumbs"]),
    setBreadcrumbs() {
      this["retailer/updateBreadcrumbs"]([
        {
          title: "List of products",
          href: undefined,
        },
      ]);
    },
    getPageData() {
      HomePage.getHomePageData()
        .then((response) => {
          this.categories = response.data.categories;
          this.banners = response.data.banners;
          this.productSections = response.data.sections;
        })
        .catch((e) => {
          console.log(e.message);
          this.noConnectedShop = true;
        });
    },
    getCategoryLogo(cID) {
      return require("../../../assets/img/temp/cat.png");
    },
    getbanner(bID) {
      return require("../../../assets/img/temp/1 1.png");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";

.no-result {
  color: green;
  padding-top: 250px;
  padding-bottom: 250px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.products-list-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 312px);
  grid-gap: 24px;
  justify-content: start;
  align-items: stretch;
}

.fake_select_wrapper {
  width: 257px !important;
}

.list_banner {
  width: calc(100% - 100px);
  margin-top: 16px;
  margin-left: 84px;

  &.isOpen {
    padding-left: 75px;
  }
}

.closeBanner {
  position: absolute;
  top: 32px;
  left: 100px;
  background: #008f4a;
  color: white;
  border: 1px solid #008f4a;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 6px;
  gap: 8px;
}
.productList__header {
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
  }
  &__listCount {
    margin: 0;
    color: #667085;
    font-size: 1.3rem;
  }
}
.popular_categories {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.banner-border {
  border-bottom: 1px solid #e4e7ec;
  margin-top: 24px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  @media (max-width: 769px) {
    justify-content: space-between;
    column-gap: 15px;
  }
  @media (max-width: 550px) {
    justify-content: center;
  }
}

.flex-container > div {
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  margin-bottom: 15px;

  @media (max-width: 769px) {
    width: 48%;
  }
  @media (max-width: 550px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
}

.flex-container-banners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-container-banners > div {
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  flex: 6 0 12%;

  @media (max-width: 769px) {
    flex: 6 0 40%;
  }
}
</style>
